body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* remove arrows input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Scrollbar css */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #0e0329;
  background-color: #0e0329;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3b2574;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #362b5c;
}

/* fonts */
@font-face {
  font-family: 'Spartan';
  src: local('Spartan-Regular'), url(./assets/fonts/Spartan-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Spartan';
  font-weight: 600;
  src: local('Spartan-Bold'), url(./assets/fonts/Spartan-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'DarkerGrotesque';
  font-weight: 600;
  src: local('DarkerGrotesque-Medium'), url(./assets/fonts/DarkerGrotesque-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'TS-Block';
  src: local('TS-Block-Bold'), url(./assets/fonts/TS-Block-Bold.ttf) format('truetype');
}
